import React, { useState, useLayoutEffect, useContext, useRef } from 'react'
import style from './index.module.scss'
import { Recruiter, getIDFromKeyword } from '../../service'
import Image from 'next/image'
import { languageContext } from 'app/components/providers/languageProvider'
// import { Preview } from '../Preview'
import { fetchViewHr } from 'store/services/companies2/fetchViewCompany'
import { getCookie } from 'helpers/cookies'
import { useParams } from 'next/navigation'
import { getDeviceUuid } from 'helpers/guest'
import { getDeviceNameTracker, viewHrSourceTracker } from 'components/ReportTracker/util'
import { getCountryId } from 'helpers/country'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
// import { useLazyConnectLogin } from 'helpers/lazyConnnectLogin'
import { RecruiterModal } from 'app/components/RecruiterModal'
export const ChatPanel = (props: { list: Recruiter[]; title; lessText; moreText }) => {
  const { list, title, lessText, moreText } = props
  const [isVisible, setIsVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(150)
  const [recruiterId, setRecruiterId] = useState(null)

  const handleClick = () => {
    setIsVisible(!isVisible)
  }
  const contentRef = useRef(null)
  const calculateContentHeight = () => {
    setContentHeight(contentRef.current?.scrollHeight ?? 0)
  }
  useLayoutEffect(() => {
    calculateContentHeight()
  })
  const _resArr = props.list
  const showMore = _resArr.length > 4

  const contextLang = useContext(languageContext)
  const { overview, tab } = contextLang.companyDetail
  console.log({ recruiterId })
  return (
    <div className={style.wrapper}>
      <div className={style.title}>{title}</div>
      <div
        className={style.animation_wrapper}
        style={{
          height: !isVisible ? (!showMore ? '' : 280) : contentHeight
        }}
      >
        <div className={style.chatList} ref={contentRef}>
          {list.map((item, index) => {
            return <ChatItem key={index} {...item} setRecruiterId={setRecruiterId} />
          })}
        </div>
      </div>
      {showMore && (
        <div className={style.containerMore}>
          <div className={`${style.more} ${isVisible ? style.lessMore : ''}`} onClick={handleClick}>
            {isVisible ? lessText : moreText}
          </div>
        </div>
      )}
      {/* {recruiterId && (
        <Preview Id={recruiterId} setRecruiterId={setRecruiterId} overview={overview}></Preview>
      )} */}
      {recruiterId && (
        <RecruiterModal
          uid={recruiterId}
          display={!!recruiterId}
          onClose={() => setRecruiterId(null)}
          lang={{}}
        ></RecruiterModal>
      )}
    </div>
  )
}

export const ChatItem = (props: Recruiter) => {
  const { keyword = '' } = useParams()
  const company_id = getIDFromKeyword(keyword as string)
  const sessionid = getCookie('sessionid')
  // const { showLoginModal } = useLazyConnectLogin()

  const viewHrTracker = async (item) => {
    try {
      if (!sessionid) {
        // showLoginModal()
        postNotification('SHOW_LOGIN_MODAL')
        return
      }
      props?.setRecruiterId(item.id)
      const deviceUuid = await getDeviceUuid()
      const trackerData = {
        source: viewHrSourceTracker.companyDetail,
        device: getDeviceNameTracker(false),
        device_udid: deviceUuid,
        company_id,
        recruiter_id: item?.id,
        country_id: getCountryId()
      }
      fetchViewHr({ payload: trackerData })
    } catch (error) {}
  }
  const { full_name, avatar, job_title } = props

  return (
    <div
      className={style.chatItem}
      onClick={() => {
        viewHrTracker(props)
      }}
    >
      <Image width={42} height={42} className={style.chatHeader} src={avatar} alt='avatar' />
      <div className={style.chatContent}>
        <div className={style.chatTitle}>{full_name}</div>
        <div className={style.chatSubtitle}>{job_title}</div>
      </div>
    </div>
  )
}
