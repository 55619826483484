import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import { recruiterJobs, fetchRecruiterDetailV2 } from 'store/services/recruiters/index'
import { Modal, Skeleton, Popover } from 'antd'
import { getLang } from 'helpers/country'
import { Global, css } from '@emotion/react'
import { ArrowSvg, CompanySafeSvg, JobTitleSvg, SalarySvg } from './svg'
import classNames from 'classnames'
import { recruiterHeadBg } from 'images'
import { LoadingOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'

const Skills = ({ data }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [sliceIndex, setSliceIndex] = useState(0)

  useEffect(() => {
    handleResize()
  }, [data])

  const handleResize = () => {
    let children = Array.from(ref.current?.children || [])
    if (!children.length) {
      return
    }

    let isInView = (element: Element) => {
      return element.getBoundingClientRect().top < (parentBoundary?.bottom || 0)
    }
    let parentBoundary = ref.current?.getBoundingClientRect?.()
    if (!parentBoundary) {
      return
    }
    let index = Array.from(children).findIndex((ele) => {
      return !isInView(ele)
    })

    if (index === -1) {
      index = data.length
    } else {
      let previousEleBound = children[index - 1]?.getBoundingClientRect?.()
      // console.log(parentBoundary.right - previousEleBound.right,index,55555)
      index > 0 &&
        parentBoundary.right - previousEleBound.right < 100 && // 72 this text can't display '...'
        index--
    }
    index = Math.max(index, 1)
    setSliceIndex(index)
  }

  if (!data.length) {
    return <div style={{ height: '32px' }}></div>
  }

  return (
    <div className={styles.skillWrapper}>
      {sliceIndex === 0 && (
        <div className={styles.skills} ref={ref}>
          {data.map((tag, index) => (
            <span key={index} className={styles.skillItem}>
              {tag}
            </span>
          ))}
        </div>
      )}

      {sliceIndex !== 0 && (
        <div className={styles.skills} style={{ flexWrap: 'nowrap' }}>
          {data.slice(0, sliceIndex).map((tag, index, arr) => {
            const isLast = index === arr.length - 1
            return (
              <span
                key={index}
                className={classNames(
                  styles.skillItem,
                  isLast ? styles.skillItemForOverflow : null
                )}
              >
                {tag}
              </span>
            )
          })}

          {Boolean(data.length - sliceIndex) && (
            // <Tooltip title={data.slice(sliceIndex).join(',')}>
            <span className={styles.skillItem}>+{data.length - sliceIndex}</span>
            // </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

const pageSize = 20

export const RecruiterModal = (props: {
  uid: string | number
  display: boolean
  lang: any
  onClose: () => void
  showCompany?: boolean
}) => {
  const { lang, display, onClose, uid, showCompany = true } = props
  const langKey = getLang()
  const [show, setShow] = useState<boolean>(false)
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const containerRef = useRef(null)
  const [loadingJob, setLoadingJob] = useState(false)
  const pageRef = useRef(1)
  const loadingRef = useRef(null)
  const totalPage = useRef(0)
  const jobsRef = useRef([])
  const [data, setData] = useState({
    total: 1,
    jobs: [],
    text: ''
  })
  useEffect(() => {
    if (!isMobile) {
      setShow(Boolean(display))
    }
  }, [display])

  useEffect(() => {
    if (!uid) {
      return
    }
    setUser({})
    let unmounted = false
    setLoading(true)
    fetchRecruiterDetailV2(uid, langKey)
      .then((res) => {
        if (unmounted || !res) {
          return
        }

        setUser(res?.data?.data)
      })
      .finally(() => setLoading(false))
    getJobs()
    return () => {
      unmounted = true
    }
  }, [uid, langKey])

  const getJobs = () => {
    recruiterJobs({
      recruiter_id: uid,
      lang: langKey,
      page_no: pageRef.current,
      page_size: pageSize
    })
      .then((res) => {
        const data = res?.data?.data || {}
        totalPage.current = data.pagination?.total_pages
        if (data.jobs?.length > 0) {
          const jobs = jobsRef.current?.concat(data.jobs)
          jobsRef.current = jobs
        }

        setData({
          total: data.pagination?.total_items,
          jobs: jobsRef.current,
          text: data.employer_recruitment_positions
        })
      })
      .finally(() => {
        loadingRef.current = false
        setLoadingJob(false)
      })
  }

  const handleScroll = () => {
    const div = containerRef.current
    if (totalPage.current - 1 < pageRef.current) {
      return
    }

    if (div.scrollHeight - div.scrollTop - div.clientHeight < 150 && !loadingRef.current) {
      loadingRef.current = true
      setLoadingJob(true)
      pageRef.current = pageRef.current + 1
      getJobs()
    }
  }

  useEffect(() => {
    if (show && !loading) {
      const div = containerRef.current
      div.addEventListener('scroll', handleScroll)

      return () => {
        div.removeEventListener('scroll', handleScroll)
      }
    }
  }, [show, loading])

  const { company_info: company = {}, badges_info = {}, recruiter_info = {} } = user

  const {
    disabled_badges = [],
    enabled_badges = [],
    obtained_achievement_medals_text,
    obtained_achievement_medals_number
  } = badges_info

  let regex = new RegExp(obtained_achievement_medals_number, 'g')
  let newStr = obtained_achievement_medals_text?.replace(regex, `<span>$&</span>`)

  return (
    <>
      <Global styles={GlobalStyles} />
      <Modal
        open={show}
        title={null}
        width={500}
        onCancel={onClose}
        footer={null}
        centered
        wrapClassName='recruiterModal'
      >
        {loading ? (
          <div
            style={{
              minHeight: '480px',
              padding: '34px'
            }}
          >
            {new Array(3).fill(1).map(() => (
              <Skeleton active />
            ))}
          </div>
        ) : (
          <div className={styles.recruiterWrapper}>
            <div
              className={styles.mask}
              style={{ backgroundImage: `url(${recruiterHeadBg})` }}
            ></div>
            <div className={styles.header}>
              <img src={recruiter_info.avatar} className={styles.avatar} />

              <p className={styles.fullName}>{recruiter_info.full_name}</p>
              <div className={styles.position}>
                <span className={styles.hrTitle}>{recruiter_info?.job_title}</span>
                {recruiter_info.reply_label ? (
                  <div className={styles.sevenDay}>{recruiter_info.reply_label}</div>
                ) : null}
              </div>
            </div>
            <div className={styles.container} ref={containerRef}>
              {enabled_badges?.length > 0 ? (
                <>
                  <h3 className={styles.obtained} dangerouslySetInnerHTML={{ __html: newStr }}>
                    {/* Obtained <span>4</span> achievement medals */}
                  </h3>
                  <div className={styles.medal}>
                    {[...enabled_badges, ...disabled_badges]
                      .filter((e) => e.disabled_logo)
                      .map((v) => {
                        return (
                          <div key={v.badge_id} className={styles.badgeItem}>
                            <Popover
                              content={
                                <div className='badgeContainer'>
                                  <img width={62} src={v.disabled_logo} />
                                  <p className='badgeTitle'>{v.type_name}</p>
                                  <p className='badgeInfo'>{v.hover_text}</p>
                                </div>
                              }
                              title=''
                            >
                              <img width={54} src={v.disabled_logo} />
                            </Popover>
                          </div>
                        )
                      })}
                  </div>
                </>
              ) : null}
              {showCompany && (
                <>
                  <h3 className={styles.title} style={{ borderTop: '1px solid #E4E4E4' }}>
                    {company.company_text}
                  </h3>
                  <div className={styles.company}>
                    <div className={styles.companyContent}>
                      <img src={company.logo_url} className={styles.companyLogo} />
                      <div style={{ flex: '1', width: 0 }}>
                        <span className={styles.companyName}>
                          {company.name} {CompanySafeSvg}
                        </span>
                        <div className={styles.labelBox}>
                          {(company?.company_tags || []).map((key, index) => {
                            return (
                              <span className={styles.label} key={key}>
                                {key}{' '}
                                {index < company?.company_tags?.length - 1 && (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='3'
                                    height='4'
                                    viewBox='0 0 3 4'
                                    fill='none'
                                  >
                                    <circle cx='1.5' cy='2' r='1.5' fill='#B8B8B8' />
                                  </svg>
                                )}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                      <a
                        className={styles.more}
                        target='_blank'
                        href={`/${langKey}/company/${company?.name}-${company?.id}`}
                      >
                        {ArrowSvg}
                      </a>
                    </div>
                  </div>
                </>
              )}

              {data?.jobs?.length > 0 ? (
                <>
                  <h3 className={styles.title}>{data.text}</h3>
                  <div className={styles.job}>
                    {data?.jobs.map((item) => (
                      <a
                        className={styles.item}
                        href={`/${langKey}/job/${item.title}-${item.id}`}
                        key={item.id}
                        target='_blank'
                      >
                        <div className={styles.jobTitle}>
                          {JobTitleSvg}
                          <p>{item.title}</p>
                        </div>
                        <div className={styles.jobTitle}>
                          {SalarySvg} <p>{item.salary}</p>
                        </div>
                        <Skills data={item.skills} />
                      </a>
                    ))}
                  </div>
                  <div className={styles.page}>
                    {loadingJob && <LoadingOutlined style={{ fontSize: '20px', color: '#06F' }} />}
                  </div>
                </>
              ) : null}
            </div>

            {/* <div className={styles.page}>
              <Pagination
                current={params.page}
                onChange={onChange}
                total={data.total}
                hideOnSinglePage
                showSizeChanger={false}
                itemRender={(current, type, originalElement) =>
                  itemRender(current, type, originalElement, params, data.total)
                }
              />
            </div> */}
          </div>
        )}
      </Modal>
    </>
  )
}
const GlobalStyles = css`
  .recruiterModal {
    .ant-modal-content {
      padding: 0;
      border-radius: 16px;
      overflow: hidden;
    }
    .ant-modal-close-x {
      width: auto;
      height: auto;
    }
  }
  .badgeContainer {
    padding: 8px;
    img {
      display: block;
      margin: 0 auto;
    }
    .badgeTitle {
      color: #121212;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 0;
      margin: 0;
    }
    .badgeInfo {
      color: #7d7d7d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`
