'use client'
import React, { useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Tooltip } from 'antd'
import { useSearchParams } from 'next/navigation'
import Image from 'next/image'

import CompanyInfo, { padArrayToMultiple } from './components/InfoList'
import Section from './components/Section'
import CulturePanel, { SocialMedia, NewsCom } from './components/Culture'
import { ChatPanel } from './components/ChatPanel'
import { useCompanyDetail } from './DataProvider'
import SearchPanel, { JobsTag } from './components/SearchPanel'
import SearchPanelRemote from './components/SearchPanelRemote'
import Link from 'components/Link'
// import { formatTemplateString } from 'helpers/formatter'
import { fetchViewCompany } from 'store/services/companies2/fetchViewCompany'
import { getCookie, removeCookie } from 'helpers/cookies'
import { COMPANY_VIEW_BURIED_COOKIE } from 'components/ReportTracker/util'
import { Salary } from './components/Salary'

import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

import style from './index.module.scss'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const VerificationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'>
      <path
        d='M11.6443 1.71163C12.2274 1.14044 13.1603 1.14044 13.7434 1.71163L15.7989 3.7249C16.0754 3.99573 16.4459 4.14919 16.833 4.15321L19.71 4.18307C20.5263 4.19154 21.1859 4.85117 21.1944 5.66742L21.2242 8.54447C21.2283 8.9315 21.3817 9.30199 21.6525 9.5785L23.6658 11.634C24.237 12.2172 24.237 13.15 23.6658 13.7332L21.6525 15.7887C21.3817 16.0652 21.2283 16.4357 21.2242 16.8227L21.1944 19.6998C21.1859 20.516 20.5263 21.1756 19.71 21.1841L16.833 21.214C16.4459 21.218 16.0754 21.3715 15.7989 21.6423L13.7434 23.6556C13.1603 24.2267 12.2274 24.2267 11.6443 23.6556L9.58875 21.6423C9.31225 21.3715 8.94175 21.218 8.55473 21.214L5.67767 21.1841C4.86142 21.1756 4.20179 20.516 4.19332 19.6998L4.16346 16.8227C4.15944 16.4357 4.00598 16.0652 3.73515 15.7887L1.72188 13.7332C1.15069 13.15 1.15069 12.2172 1.72188 11.634L3.73515 9.5785C4.00598 9.30199 4.15944 8.9315 4.16346 8.54447L4.19332 5.66742C4.20179 4.85117 4.86142 4.19154 5.67767 4.18307L8.55473 4.15321C8.94175 4.14919 9.31225 3.99573 9.58875 3.7249L11.6443 1.71163Z'
        fill='#2378E5'
      />
      <path
        d='M8.54541 12.2185L11.5978 15.2874L16.8413 10.0791'
        stroke='white'
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const CompanyHeader = ({ dictionary, companyHeaderData }) => {
  return (
    <div className={style.header}>
      <div className={style.header_info}>
        <div className={style.header_logo}>
          <Image
            className={style.header_logo_img}
            width={68}
            height={68}
            src={companyHeaderData.logo_url}
            alt='logo'
          />
        </div>
        <div className={style.header_content}>
          <div className={style.header_title}>
            {companyHeaderData.name}
            {companyHeaderData.is_verify && (
              <Tooltip title={dictionary?.search?.verifiedCompanyBadge}>
                <span className={style.header_title_verified}>
                  <VerificationIcon />
                </span>
              </Tooltip>
            )}
          </div>
          <div className={style.header_subtitle}>
            {(companyHeaderData.company_tags || []).filter(Boolean).map((value, index) => {
              return (
                <span key={index} className={style.header_subtitle_item}>
                  {value}
                </span>
              )
            })}
          </div>
        </div>
        <div className={style.header_title_right}>
          <div className={style.header_title_num_wrapper}>
            <span className={style.header_title_num}>{companyHeaderData.job_num || 0}</span>
            <span className={style.header_title_string}>
              {
                // dictionary.comm.jobs
                companyHeaderData.job_num_desc
              }{' '}
            </span>
          </div>
          <div className={style.header_title_num_wrapper}>
            <span className={style.header_title_num}>
              {
                companyHeaderData.member_num
                // hrLength
              }
            </span>
            <span className={style.header_title_string}>
              {/* {dictionary.comm.boss} */}
              {companyHeaderData.member_num_desc}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  if (value !== index) {
    return null
  }

  return (
    <div id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {children}
    </div>
  )
}

export const HotJob = ({ item, lang, overview }) => {
  return (
    <Link
      className={style.jobs_item}
      style={{ cursor: 'pointer' }}
      href={`/${lang}${item.job_url}?source=company_detail`}
      target={'_blank'}
      // title={item.job_title}
    >
      <div className={style.header_box}>
        <div className={style.jobs_item_title} title={item.job_title}>
          {item.job_title}
        </div>
      </div>
      <div className={style.jobs_chat_now_container}>
        <div className={style.salary}>
          <Salary salaryType={item.salary_info.type_text} salaryRange={item.salary_info.text} />
        </div>
        <div className={style.chat_now}>{overview.jobs.card.chatNow}</div>
      </div>
      <div className={style.jobs_content}>
        <JobsTag data={item.job_tags} count={3} style={{ flex: 1, overflow: 'hidden' }} showTitle />
      </div>
    </Link>
  )
}

const Page = () => {
  const searchParams = useSearchParams()
  const tabName = searchParams.get('tab')
  const [value, setValue] = React.useState(
    tabName === 'jobs' ? 1 : tabName === 'remoteJobs' ? 2 : 0
  )
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const {
    jobs,
    lang,
    jobClassesRemote,
    locationRemote,
    languageRemote,
    remoteJobs,
    dictionary,
    companyPageData
  } = useCompanyDetail()

  const { tab, overview } = dictionary?.companyDetail || {}

  const tab_title = [tab.CompanyInformation]

  const remoteNum = remoteJobs?.company_remote_jobs?.total_num,
    localNum = (jobs as any)?.company_jobs?.job_nums
  // console.log('remoteJobscompanyPageData', companyPageData, remoteJobs)
  useEffect(() => {
    if (localNum == 0) {
      remoteNum > 0 && tabName ? setValue(2) : setValue(0)
    }
  }, [jobs, tabName, remoteJobs])

  const params = getCookie(COMPANY_VIEW_BURIED_COOKIE)
  useEffect(() => {
    if (params) {
      fetchViewCompany({ ...params })
        .then(() => {
          removeCookie(COMPANY_VIEW_BURIED_COOKIE)
        })
        .catch((e) => console.log(e))
    }
  }, [])
  const headerData = companyPageData?.company_header || {}
  const companyInfo = companyPageData?.company_info || {}

  const companyJobs = (jobs as any)?.company_jobs || {}

  const companyRemoteJobs = companyPageData?.company_remote_jobs || {}
  const hotJobSection = companyInfo?.company_hot_job || {}
  const hotJobs = hotJobSection?.hot_jobs || []
  //{ }// { jobs: companyJobs.company_hot_job }
  const { company_members, company_news } = companyPageData?.company_info || {}

  return (
    <div className={style.container}>
      <CompanyHeader companyHeaderData={headerData} dictionary={dictionary} />

      <div className={style.content}>
        <div id='tabAnchor' />
        <div className={style.tab_wrapper}>
          <div className={style.tab_layout}>
            <Tabs
              value={value}
              onChange={(event, value) => {
                handleChange(event, value)
                if (value) {
                  let anchor = document.getElementById('tabAnchor')
                  if (anchor.getBoundingClientRect().top > 0) {
                    // still in view
                    return
                  }
                  anchor.scrollIntoView?.()
                } else {
                  window.scroll({
                    top: 0
                  })
                }
              }}
              classes={{
                indicator: 'indicator'
              }}
              sx={{
                fontWeight: 400
              }}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab label={tab_title[0]} {...a11yProps(0)} />
              {Boolean(companyJobs?.total_page) ? (
                <Tab label={companyJobs.title} {...a11yProps(1)} />
              ) : (
                <Tab label={''} style={{ width: 0, minWidth: 0, padding: 0 }} />
              )}
              {/* {Boolean(companyRemoteJobs?.job_nums) && (
                <Tab label={companyRemoteJobs.title} {...a11yProps(1)} />
              )} */}
            </Tabs>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          {Boolean(hotJobs?.length) && (
            <div className={style.hot_jobs_wrapper}>
              <Section title={hotJobSection?.title} hot={hotJobSection?.label_text}>
                <div
                  className={style.header_right}
                  onClick={(e) => {
                    handleChange(e, 1)
                  }}
                >
                  {/* {overview.ViewAllJobs} */}
                  {hotJobSection?.more_text}
                  <div className={style.arrow}></div>
                </div>
                <div className={style.jobs_item_layout}>
                  {padArrayToMultiple(hotJobs.slice(0, 3))(3).map((item, index) => {
                    if (!item)
                      return <div key={index} className={style.jobs_item} style={{ opacity: 0 }} />
                    return <HotJob key={index} item={item} lang={lang} overview={overview} />
                  })}
                </div>
              </Section>
            </div>
          )}
          <div className={style.company_info_tab_wrapper}>
            <div className={style.company_info_wrapper}>
              <CompanyInfo
                isMobile={false}
                // jobs={[]}
                onMore={(e) => {
                  window.scrollTo({
                    top: 0
                  })
                  handleChange(e, 1)
                }}
              />
              <div className={style.company_culture}>
                <CulturePanel
                  cultures={companyInfo.company_culture}
                  benefits={companyInfo.company_benefits}
                />
                {Boolean(company_members?.members?.length) && (
                  <ChatPanel
                    title={company_members.title}
                    list={company_members.members}
                    lessText={company_members.less_text}
                    moreText={company_members.more_text}
                  />
                )}
                <SocialMedia />
                {company_news?.data?.length > 0 && <NewsCom news={company_news}></NewsCom>}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SearchPanel dictionary={dictionary} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <SearchPanelRemote
            functions={jobClassesRemote}
            locationRemote={locationRemote}
            languageRemote={languageRemote}
            remoteJobs={remoteJobs}
          />
        </TabPanel> */}
      </div>
    </div>
  )
}

export default Page
